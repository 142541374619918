import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Dashbot, the leading conversational analytics platform, entered into a strategic partnership with `}<a parentName="p" {...{
        "href": "https://octaneai.com/"
      }}>{`Octane AI`}</a>{`, a chatbot creation platform focused on e-commerce brands. The partnership will provide e-commerce companies building Facebook Messenger chatbots on Octane AI the ability to track performance and iterate their bots by leveraging Dashbot’s advanced conversational analytics, without any coding.`}</p>
    <p>{`We’re excited to partner with Octane AI because they are headstrong about changing the online shopping industry with their advanced conversational products and tools. The brands that Octane AI work with have yielded lifts in revenue and engagement because of benefits such as abandoned cart recovery, purchase follow-ups, marketing automation campaigns, and more. Most notably, VerClare Boutique increased monthly revenue by 14.3% and Apt2B Increased Revenue by 17%.`}</p>
    <p>{`Arte Merritt, co-founder and CEO of Dashbot, expresses his excitement for the new partnership: “Platforms like Octane AI make it easy to build engaging conversational products that help with conversions and revenue, and with this simple integration with Dashbot analytics, business owners will have a full suite of tools to build and optimize their Messenger bots. I’m really excited to see what our customers create and how this partnership will help them grow.”`}</p>
    <p>{`Get the integration steps here: `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/docs/builders/octaneai/"
      }}>{`https://www.dashbot.io/docs/builders/octaneai/`}</a></p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/05/22163950/OctaneAI_Platform-1024x643.png",
        "alt": null
      }}></img></p>
    <div className="entry-content clr"><span className="s3">About [<span className="s2">Dashbot</span>](https://www.dashbot.io/)</span>
      <p><em parentName="p"><span className="s1">{`Dashbot is a conversational analytics platform that enables developers and brands to increase engagement, acquisition, and monetization through actionable data and tools. In addition to traditional analytics like engagement and retention, we provide bot and voice specific metrics including sentiment analysis, conversational analytics, and full chat session transcripts. We also have tools to take action on the data, like our live-person takeover, broadcast messaging, and goal funnels.`}</span></em></p>
      <span className="s1">About [Octane AI](https://octaneai.com/)</span>
      <p><em parentName="p">{`Octane AI is a conversational marketing technology that takes the top performing email campaigns from online stores and enables brands to send them as interactive conversational campaigns on Facebook Messenger. Campaigns powered by Octane AI out perform equivalent email campaigns in reach, open rate, and conversion. We believe the future of e-commerce is in automated conversational experiences. This will have major implications on outbound marketing, customer service, and brand relationships.`}</em></p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      